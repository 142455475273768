import { Link, Outlet } from "react-router-dom";

export const Layout = ({ children }) => {
  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
            share across all the pages on your site, like navigation. */}

      <div className="flex justify-center space-x-6 py-4">
        <Link to="/">NFTs</Link>
        <Link to="/profile">Profile</Link>
      </div>

      {children}

      {/* An <Outlet> renders whatever child route is currently active,
            so you can think about this <Outlet> as a placeholder for
            the child routes we defined above. */}
      <Outlet />
    </div>
  );
};
