import React, { useState, useEffect } from "react";
import placeholder from "../nft-placehoder.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { createPsbt } from "../utils/pipeWallet";

export const Profile = () => {
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState("");
  const [psbt, setPsbt] = useState("");
  const [currentToken, setCurrentToken] = useState({});

  const navigate = useNavigate();

  const handleListClick = async (nft) => {
    await createPsbt(
      // "tb1p2zk3s9aq43k38qzj3mvkyzj97e4teyrh8t49rp24pdnwdl5xefxsp0n3g6",
      address,
      1,
      nft.tickerID
    );

    setCurrentToken(nft);
  };

  const processImage = (token) => {
    const binaryData = new Uint8Array(
      token?.image?.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );

    // Create a Blob object from the binary data
    const blob = new Blob([binaryData], { type: token.mimeType });

    // Create a data URL from the Blob
    const imageUrl = URL.createObjectURL(blob);

    return imageUrl;
  };

  const fetchNfts = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/assets`,
      {
        address,
      }
    );
    const response2 = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/listings`,
      {
        address,
      }
    );
    const data = [];
    // const nftsArray = [];
    const tokensArray = [];
    for (let i = 0; i < response2.data.length; i++) {
      console.log(response2.data[i]);
      if (response2.data[i].address !== address) {
        data.push(response2.data[i]);
      }
    }
    for (let i = 0; i < response.data.tokens.length; i++) {
      console.log(response.data.tokens[i]);
      const token = response.data.tokens[i];
      // nftsArray.push(token);
      data.push({
        ...response.data.tokens[i],
        image: token?.data,
        address: address,
      });

      tokensArray.push(token.tickerID);
    }
    for (let i = 0; i < response.data.nfts.length; i++) {
      console.log(response.data.nfts[i]);
      const token = response.data.nfts[i];
      //   nftsArray.push(token);
      data.push({
        ...response.data.nfts[i],
        image: token?.data,
        address: address,
      });

      tokensArray.push(token.tickerID);
    }
    // for (let i = 0; i < response.data.length; i++) {
    //   console.log(response.data[i]);
    //   const token = response.data[i];

    //   data.push({
    //     ...response.data[i],
    //     image: token.data,
    //     address: address,
    //   });
    // }

    const newData = [];

    const tokenIDs = new Set();

    for (let i = 0; i < data.length; i++) {
      if (!tokenIDs.has(data[i].tickerID)) {
        newData.push(data[i]);
        tokenIDs.add(data[i].tickerID);
      }
    }

    setNfts(newData);
    setLoading(false);
  };

  useEffect(() => {
    if (localStorage.getItem("address")) {
      setAddress(localStorage.getItem("address"));
    } else {
      navigate("/signin");
    }
  }, [navigate]);

  useEffect(() => {
    if (address) {
      fetchNfts();
    }
    // eslint-disable-next-line
  }, [address]);

  useEffect(() => {
    // listen to createPsbt event
    window.addEventListener("createPsbtForSite", (event) => {
      console.log(event.detail);
      const { psbt } = event.detail;
      //   setCurrentToken({ ...currentToken, psbt: psbt });
      setPsbt(psbt);
    });

    return () => {
      window.removeEventListener("createPsbtForSite", () => {});
    };
  }, []);

  useEffect(() => {
    console.log("currentToken: ", currentToken, "psbt: ", psbt);
    if (psbt && currentToken.pid) {
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/create-listing`, {
        address,
        ticker: currentToken.tickerID,
        image: currentToken.image,
        mimeType: currentToken.mimeType,
        price: 0,
        psbt: psbt,
        pid: currentToken.pid,
        owner: address,
      });

      setCurrentToken({});

      fetchNfts();

      alert("Item listed successfully");
    }
    // eslint-disable-next-line
  }, [currentToken, psbt]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-2xl font-semibold">Loading...</p>
      </div>
    );
  }

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 xl:grid-cols-4 max-w-7xl mx-auto px-4">
      {Array.from(nfts).map((nft, i) => (
        <div key={i} className="relative group overflow-hidden rounded-lg">
          <img
            alt="NFT 1"
            className="object-cover w-full h-60"
            height={400}
            src={processImage(nft) || placeholder}
            style={{
              aspectRatio: "600/400",
              objectFit: "cover",
            }}
            width={600}
          />
          <div className="grid gap-1.5 p-4">
            <h3 className="font-semibold text-base md:text-xl lg:text-base xl:text-xl">
              {nft?.tickerID || nft?.ticker}
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {address.slice(0, 8)}...{address.slice(-8)}
            </p>
            {/* {!nft.psbt && ( */}
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => handleListClick(nft)}
              >
                List this item
              </button>
            {/* )} */}
          </div>
        </div>
      ))}
    </div>
  );
};
