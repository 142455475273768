import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connectWallet } from "../utils/pipeWallet";

export const SignIn = () => {
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const navigate = useNavigate();

  const handleSignIn = async () => {
    setLoading(true);
    console.log("Sign in button clicked");
    // connectWallet()
    const address = await connectWallet();
    localStorage.setItem("address", address.testnet);
    setAddress(address.testnet);
    setLoading(false);
    setIsLoggedIn(true);
    // navigate("/");
  };

  const handleRedirect = () => {
    navigate("/");
  };

  useEffect(() => {
    window.addEventListener("connectToSite", (event) => {
      console.log("connect event: ", event);
      setAddress(event.detail.connectedWallet.testnet);
      localStorage.setItem("address", event.detail.connectedWallet.testnet);
      setIsLoggedIn(true);
    });

    return () => {
      window.removeEventListener("connectToSite", () => {});
    };
  }, []);

  return (
    <div>
      <h1>Sign In</h1>
      {!isLoggedIn ? (
        <div className="flex flex-col items-center">
          <button
            onClick={handleSignIn}
            className="px-4 py-2 bg-blue-600 text-white rounded-md"
          >
            {loading ? "Loading..." : "Sign In"}
          </button>
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <p>
            You are logged in with {address} address. Do you want to change it?
          </p>
          <button
            onClick={handleRedirect}
            className="px-4 py-2 bg-blue-600 text-white rounded-md"
          >
            Go to home
          </button>
          <button className="px-4 py-2 bg-red-600 text-white rounded-md">
            Change address
          </button>
        </div>
      )}
    </div>
  );
};
