export const connectWallet = async () => {
  //   await window.pipe.connect();
  //   const address = window.pipe.address;
  //   // console.log(window.pipe.address);
  //   console.log(address);
  //   //   console.log(wallet.connectedWallet);
  //   //   return wallet.connectedWallet;
  //   //   return window.pipe.address;
  //   return address;

  return new Promise((resolve, reject) => {
    window.pipe.connect(); // Вызываем метод connect из объекта window.pipe

    // Создаем обработчик события, который будет вызываться при установке значения window.pipe.address
    const handleAddressChange = (event) => {
      const address = event.detail.connectedWallet;
      console.log(address);
      resolve(address); // Разрешаем промис и передаем значение address
    };

    // Добавляем обработчик события
    window.addEventListener("connect", handleAddressChange);

    // Отписываемся от обработчика события после получения значения address
    return () => {
      window.removeEventListener("connect", handleAddressChange);
    };
  });
};

export const createPsbt = async (recipient, amount, ticker) => {
  const psbt = await window.pipe.createPsbt(recipient, amount, ticker);
  return psbt;
};

export const sendTransaction = async (address, amount = 1, psbt) => {
  const response = await window.pipe.sendTransaction(address, amount, psbt);
  //   const address = window.pipe.
  return response;
};
