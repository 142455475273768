import { useState, useEffect } from "react";
import placeholder from "../nft-placehoder.png";
import { sendTransaction } from "../utils/pipeWallet";

export const Home = () => {
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState("");

  const handleBuyClick = async (nft) => {
    console.log("Buy button clicked");
    await sendTransaction(address, 1, encodeURIComponent(nft.psbt));
    // await sendTransaction(address, 2000);
    // const response = await axios.post(
    //   `${process.env.REACT_APP_BACKEND_URL}/api/buy-listing`,
    //   {
    //     address: address,
    //     pid: nft.pid,
    //   }
    // );
    // console.log(response.data);
    // fetchNfts();
    // alert("NFT bought successfully");
  };

  const fetchNfts = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/listings`
    );
    const data = await response.json();
    const d = [];
    for (let i = 0; i < data.length; i++) {
      const token = data[i];
      // Convert the hexadecimal data to binary
      const binaryData = new Uint8Array(
        token.image?.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
      );

      // Create a Blob object from the binary data
      const blob = new Blob([binaryData], { type: token.mimeType });

      // Create a data URL from the Blob
      const imageUrl = URL.createObjectURL(blob);
      d.push({ ...data[i], image: imageUrl });
    }
    setNfts(d);
    setLoading(false);
  };

  useEffect(() => {
    if (localStorage.getItem("address")) {
      setAddress(localStorage.getItem("address"));
    }
    fetchNfts();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-2xl font-semibold">Loading...</p>
      </div>
    );
  }

  return (
    <>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 xl:grid-cols-4 max-w-7xl mx-auto px-4">
        {nfts.map((nft, i) => (
          <div key={i} className="relative group overflow-hidden rounded-lg">
            <img
              alt="NFT 1"
              className="object-cover w-full h-60"
              height={400}
              src={nft?.image || placeholder}
              style={{
                aspectRatio: "600/400",
                objectFit: "cover",
              }}
              width={600}
            />
            <div className="grid gap-1.5 p-4">
              <h3 className="font-semibold text-base md:text-xl lg:text-base xl:text-xl">
                {nft?.ticker}
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {nft.address.slice(0, 8)}...{nft.address.slice(-8)}
              </p>
              <h4 className="font-semibold text-base md:text-lg xl:text-base">
                $199.99
              </h4>
              {/* {nft.psbt && ( */}
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-md"
                  onClick={() => handleBuyClick(nft)}
                >
                  Buy
                </button>
              {/* )} */}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
