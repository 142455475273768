import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home, Profile, SignIn } from "./pages";
import { Layout } from "./Layout";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route path="/signin" element={<SignIn />} />
      </Routes>
    </>
  );
}
export default App;
